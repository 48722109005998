/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it

import React from "react";

import ModalProvider from "./src/context/modalContext";

import "./src/styles/global.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./src/styles/carousel.css";

export const wrapRootElement = ({ element }) => (
  <ModalProvider>{element}</ModalProvider>
);
